/**
* Top50 had a solution with mailchimp at first
* Afterwards they moved to mailjet
* The Code from the mailchimp solution is still present and was not changed
* This is why there is a lot of id's with an identical class
* The mailchimp solution used id's but as it is not best practice
* Classes were used for the mailjet integration
**/


$newsletter-color: #ffffff;
$newsletter-color-bg: #0C4DA2;

.app-jqfs-wrapper {
    width: 100%;
    margin: 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;

    .help-block {
        font-size: 16px;
        width: 100%;

        display: block;
        margin-top: 5px;
        margin-bottom: 10px;
        color: #a94442;
    }

    #msgIncomplete,
    .msg-incomplete {
        color: #a94442;
        background-color: transparent;
        border: none;
        font-size: 16px;
        padding: 0;
        margin: 0;
    }

    .form-group {
        margin-bottom: 0;
    }

    .form-control {
        padding: 7px 0;
    }

    .app-jqfs-recaptcha {
        overflow: hidden;
        clear: both;
    }

    #sendFormWithApi,
    #subToMc,
    .sub-to-mj {
        padding: 10px 20px;
    }

    textarea {
        resize: vertical;
    }

    .form-control.select-control {
        padding: 6px 0;
    }

    .alert {
        width: 100%;
    }

    #subToMc,
    .sub-to-mj {
        border: 1px solid $newsletter-color;
        margin-top: 20px;
        padding: 8px 12px;
    }

    .form-group {
        position: relative;

        label {
            font-size: 12px;
        }
    }

    .form-control,
    .select-control {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid $newsletter-color;
        color: $newsletter-color;
        background: $newsletter-color-bg;
    }
}

.app-jqfs-field-required:after {
    content: "   *";
    font-size: 12px;
    color: #a94442;
    display: inline-block;
    padding: 0 0 0 5px;
    vertical-align: top;
}

.app-app-jqfs-message {
    display: none;
}


/* this will disable the areas that should be, but not the messages for example */
.app-jqfs-wrapper.disable .app-jqfs-support-disable {
    pointer-events: none;
    opacity: 0.4;
}

.alert>p {
    font-size: 16px;
}

/* Newsletter styles */
#NewsletterSuccessMsg,
#NewsletterFailedMsg,
#FormReminder,
.form-reminder,
.newsletter-success-msg,
.newsletter-failed-msg {
    margin-top: 10px;
    display: none;
    color: #a94442;
}